import React, { useLayoutEffect, useRef } from 'react';
import { useTheme } from '../../../utils/context/theme-context';
import {
    WagtailModulePagesBreadCrumb,
    WagtailModulePagesBreadCrumbItem,
} from '../../../utils/WagtailModulePagesBreadCrumb';
import CTABlock from '../../sharedComponents/cta-block/CTABlock';
import {
    CTA_BLOCK_BUTTON_VARIANT,
    CTA_BLOCK_LARGE_SIZE,
    CTA_BLOCK_REGULAR_SIZE,
} from '../../sharedComponents/cta-block/utils';
import LazyloadImage from '../../sharedComponents/LazyloadImage';
import { BaseModuleProps, ICTABlock, IImageBlock } from '../types';
import { convertContentfulVariantToEnum, sanitizeHTML } from '../utils';
import './HeroCallToAction.scss';

interface IHeroCallToAction {
    headline: string;
    subText?: string;
    cta: ICTABlock;
    image: IImageBlock;
    breadcrumbs?: WagtailModulePagesBreadCrumbItem[];
    isContentFromContentful?: boolean;
}

export type HeroCallToActionProps = IHeroCallToAction & BaseModuleProps;

const HeroCallToAction = ({
    headline,
    subText,
    cta,
    image,
    variant,
    breadcrumbs,
    isContentFromContentful,
}: HeroCallToActionProps) => {
    if (isContentFromContentful) {
        cta = Array.isArray(cta) ? cta[0] : cta;
        variant = convertContentfulVariantToEnum(variant);
    }
    const baseOptions = { variant };
    const theme = useTheme();
    const [backgroundClass] = theme.generateStyles('background', baseOptions);
    const [overlayClass] = theme.generateStyles(
        'overlay-gradient',
        baseOptions,
    );
    const [titleClass] = theme.generateStyles('text', baseOptions);
    const [subtitleClass] = theme.generateStyles('text', baseOptions);
    // for headline we get richtext from contentful so need to access the rendered html to attach css class
    const parentTitleRef = useRef<HTMLDivElement>(null);
    useLayoutEffect(() => {
        if (parentTitleRef.current) {
            const parentElement = parentTitleRef.current;
            const children = Array.from(parentElement.children);

            children.forEach((child) => {
                child.classList.add(titleClass);
            });
        }
    }, [isContentFromContentful, titleClass]);

    return (
        <section className={`hero-cta ${backgroundClass}`}>
            <div className={`hero-cta__image-container ${overlayClass}`}>
                <LazyloadImage
                    alt={headline}
                    image={image.imageUrl}
                    style={{}}
                />
            </div>
            <div className="module-landing-page__module-container-content">
                {breadcrumbs ? (
                    <WagtailModulePagesBreadCrumb
                        breadcrumbs={breadcrumbs}
                        containerClasses={`hero-cta__breadcrumbs ${subtitleClass}`}
                        navClasses="breadcrumbs--no-margin"
                    />
                ) : null}
                {isContentFromContentful ? (
                    <div
                        id="hero-cta-headline-richtext"
                        ref={parentTitleRef}
                        // eslint-disable-next-line react/no-danger
                        dangerouslySetInnerHTML={{
                            __html: sanitizeHTML(headline),
                        }}
                    ></div>
                ) : (
                    <h1 className={titleClass}>{headline}</h1>
                )}
                {subText && (
                    <span data-testid="subtext" className={subtitleClass}>
                        {subText}
                    </span>
                )}
                <CTABlock
                    callToAction={cta.callToAction}
                    url={cta.url}
                    variant={CTA_BLOCK_BUTTON_VARIANT}
                    size={
                        cta.size == CTA_BLOCK_LARGE_SIZE
                            ? CTA_BLOCK_LARGE_SIZE
                            : CTA_BLOCK_REGULAR_SIZE
                    }
                    themeVariant={variant}
                />
            </div>
        </section>
    );
};
export default HeroCallToAction;
